var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"pa-5",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","nudge-left":"270px","nudge-top":"-3px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],ref:"dobText",staticClass:"d-inline-block mr-2",attrs:{"type":"tel","label":"Date of Birth","placeholder":"DD/MM/YYYY","rules":_vm.dobTextRules},on:{"keyup":_vm.dobTextChanged},model:{value:(_vm.form.dobText),callback:function ($$v) {_vm.$set(_vm.form, "dobText", $$v)},expression:"form.dobText"}}),_c('v-icon',_vm._g(_vm._b({staticClass:"d-inline-block mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":_vm.formattedMaxDateOfBirth,"locale":"en-GB"},on:{"change":_vm.dateOfBirthChanged},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}})],1),(
      _vm.dobDateError &&
      (_vm.form.dob === 0 ||
        _vm.form.dob === null ||
        _vm.form.dob === '' ||
        _vm.form.dobText === '')
    )?_c('div',{},[_c('span',{staticClass:"red--text"},[_vm._v(" Please select a valid date. ")])]):_vm._e(),(_vm.contactAddresses && _vm.contactAddresses.length > 0)?_c('div',_vm._l((_vm.contactAddresses),function(address,index){return _c('div',{key:address.addressId},[_c('AddressAndCreditInformation',{attrs:{"address":address,"lastOne":index === _vm.contactAddresses.length - 1},on:{"lastOneEditShowClicked":_vm.lastOneEditShowClicked}})],1)}),0):_vm._e(),(_vm.showAddAddressBar)?_c('CreditInformation',{staticClass:"mt-4",attrs:{"contact-id":_vm.selectedContact.contactId,"selectedAddressIdProp":_vm.selectedAddressId},on:{"selected-address-id":_vm.selectedAddressIdChanged,"fetchContactAddress":function($event){return _vm.fetchContactAddresses(_vm.selectedContact.contactId)}}}):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('v-btn',{attrs:{"depressed":"","disabled":!_vm.valid,"color":"accent","block":"","dark":"","large":""},on:{"click":_vm.submitForm}},[_vm._v(" Save Details ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }