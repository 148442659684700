<template>
  <v-form class="pa-5" ref="form" v-model="valid" lazy-validation>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      nudge-left="270px"
      nudge-top="-3px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          type="tel"
          class="d-inline-block mr-2"
          label="Date of Birth"
          v-mask="'##/##/####'"
          ref="dobText"
          placeholder="DD/MM/YYYY"
          v-model="form.dobText"
          :rules="dobTextRules"
          @keyup="dobTextChanged"
        ></v-text-field>
        <v-icon class="d-inline-block mr-2" v-bind="attrs" v-on="on">
          mdi-calendar
        </v-icon>
      </template>
      <v-date-picker
        ref="picker"
        v-model="dob"
        :max="formattedMaxDateOfBirth"
        @change="dateOfBirthChanged"
        locale="en-GB"
      ></v-date-picker>
    </v-menu>
    <div
      v-if="
        dobDateError &&
        (form.dob === 0 ||
          form.dob === null ||
          form.dob === '' ||
          form.dobText === '')
      "
      class=""
    >
      <span class="red--text"> Please select a valid date. </span>
    </div>
    <div v-if="contactAddresses && contactAddresses.length > 0">
      <div
        v-for="(address, index) in contactAddresses"
        :key="address.addressId"
      >
        <AddressAndCreditInformation
          :address="address"
          @lastOneEditShowClicked="lastOneEditShowClicked"
          :lastOne="index === contactAddresses.length - 1"
        ></AddressAndCreditInformation>
      </div>
    </div>
    <CreditInformation
      v-if="showAddAddressBar"
      v-on:selected-address-id="selectedAddressIdChanged"
      class="mt-4"
      :contact-id="selectedContact.contactId"
      :selectedAddressIdProp="selectedAddressId"
      @fetchContactAddress="fetchContactAddresses(selectedContact.contactId)"
    >
    </CreditInformation>
    <div class="mt-4">
      <v-btn
        depressed
        :disabled="!valid"
        color="accent"
        @click="submitForm"
        block
        dark
        large
      >
        Save Details
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"

import dayjs from "dayjs"
import { mask } from "vue-the-mask"
import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import api from "@/store/api"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import AddressAndCreditInformation from "@/components/forms/address_and_credit_information"
import CreditInformation from "@/components/forms/credit-info-sc"

// import { getContactState } from "@/store/helpers/default-state"

export default {
  name: "CreditInfoForm",
  components: { AddressAndCreditInformation, CreditInformation },
  data: () => ({
    dobDateError: false,
    menuFromDate: false,
    selectedAddressId: null,
    valid: true,
    menu: false,
    dobText: null,
    showAddAddress: false,
    showAddAddressBar: true,
    addressList: [],
    addresses: [],
    showPostcode: false,
    postcodeSelected: null,
    selectedAddressForEdit: {},
    inCorrectPostcodeSelected: false,
    form: {
      dob: null,
      contactAddresses: [],
      dateOfFromText: "",
      dateOfToText: "",
      fromDate: "",
      toDate: "",
      dobText: null,
    },
    formattedDate: {
      date: true,
      datePattern: ["d", "m", "Y"],
      delimiter: "/",
    },
    fromDateRules: [(value) => !!value || "Please Pick a date"],
    toDateRules: [(value) => !!value || "Please Pick a date"],
    contactId: "",
  }),
  directives: { mask },
  computed: {
    ...mapGetters("contacts", [
      "contactAddressItems",
      "selectedContact",
      "contactAddresses",
      "contacts",
    ]),
    ...mapGetters("company", ["companyId"]),
    ...mapGetters("ui", ["formEditMode"]),
    // formattedDate() {
    //   return this.form.dob
    //     ? dayjs(this.form.dob).format("DD/MM/YYYY")
    //     : ""
    // },

    dob: {
      get: function () {
        const dob = this.form.dob
          ? dayjs(this.form.dob * 1000).format("YYYY-MM-DD")
          : ""
        return dob === "Invalid Date" ? "" : dob
      },
      set: function (newVal) {
        this.form.dob = newVal
      },
    },
    formattedMaxDateOfBirth() {
      const maxDate = dayjs()
        .subtract(18, "year")
        .add(1, "day")
        .format("YYYY-MM-DD")
      return dayjs(maxDate).toISOString()
    },
    dobTextRules() {
      return [
        (v) => {
          const maxDate = dayjs().subtract(18, "year").format("YYYY-MM-DD")
          const dobText = v.split("/").reverse().join("-")
          const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
          const diff = dayjs(currentDOB).diff(maxDate, "day")
          let validate = true
          if (diff >= 1 && v.length >= 10) {
            validate = false
          }

          return !!validate || "Must be at least 18 years old"
        },
      ]
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"))
    },
    creditDetails(newVal, oldVal) {
      if (newVal.contactId !== oldVal.contactId) {
        this.form.dobText = newVal.dobText
          ? dayjs(newVal.dobText * 1000).format("YYYY-MM-DD")
          : ""
        this.form.dob = newVal.dob
          ? dayjs(newVal.dob * 1000).format("DDMMYYYY")
          : ""
      }
    },
    selectedContact(newVal, oldVal) {
      if (newVal.contactId !== oldVal.contactId) {
        this.bindCreditInformation()
        const dob = this.form.dob
          ? dayjs(this.form.dob * 1000).format("YYYY-MM-DD")
          : ""

        this.form.dobText = dob
          ? dayjs(this.form.dob * 1000).format("DD/MM/YYYY")
          : ""

        // if(dob === "") {
        //   this.form.dob = dayjs().subtract(18, 'year').format('YYYY-MM-DD')
        // }
      }
    },
    contactAddressItems(newVal, oldVal) {
      if (newVal.contactId !== oldVal.contactId) {
        this.contactAddressItems.addressId = newVal.addressId
      }
    },
  },
  created() {
    this.bindCreditInformation()
    const dob = this.form.dob
      ? dayjs(this.form.dob * 1000).format("YYYY-MM-DD")
      : ""

    this.form.dobText = dob
      ? dayjs(this.form.dob * 1000).format("DD/MM/YYYY")
      : ""

    // if(dob === "") {
    //   this.form.dob = dayjs().subtract(18, 'year').format('YYYY-MM-DD')
    // }
  },
  methods: {
    ...mapMutations("contacts", {
      selectContact: "setSelectedContact",
    }),
    ...mapActions("contacts", [
      "fetchContacts",
      "fetchContactAddresses",

      "updateContact",
      "createContact",
    ]),
    ...mapActions("addresses", ["fetchAddressesByPostcode", "updateAddress"]),

    selectedAddressIdChanged(value) {
      this.contactAddressItems.addressId = value
      this.selectedAddressId = value
    },
    lastOneEditShowClicked(value) {
      console.log(value, "showAddressBar")
      this.showAddAddressBar = value
    },
    async submitForm() {
      if (
        this.form.dob === "" ||
        this.form.dob === null ||
        this.form.dob === 0 ||
        this.form.dobText === 0
      ) {
        this.dobDateError = true
        return false
      }

      // if(this.formEditMode){
      const maxDate = dayjs().subtract(18, "year").format("YYYY-MM-DD")
      const dobText = this.form.dobText.split("/").reverse().join("-")
      const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
      const diff = dayjs(currentDOB).diff(maxDate, "day")
      if (diff >= 1) {
        const form = {
          dobText: this.form.dobText,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }
      // const contactDetails = {
      //   securityPassword: this.form.securityPassword
      // }
      // await this.updateContact({
      //   ...this.selectedContact,
      //   contactDetails})
      const creditDetails = {
        dob: dayjs(this.form.dob).unix(),
        contactId: this.selectedContact.contactId,
        password: this.selectedContact.securityPassword,
      }
      const response = await api.post("updateDob", creditDetails)
      if (response && response.status === 200) {
        // this.creditDetails = ''
        this.contactId = this.selectedContact.contactId
        changesSaved()
        const res = await this.fetchContacts()
        if (res && res.status === 200) {
          setTimeout(() => {
            this.selectContact(this.contactId)
          }, 3000)
        }
      } else {
        somethingWentWrong()
      }
      // }
    },

    async saveAddress() {
      const filteredSelectedAddress = this.contactAddresses.filter(
        (address) => address.addressId
      )

      const addressDetails = {
        addressId: this.showAddAddress ? filteredSelectedAddress : 0,
        add1: this.form.add1,
        add2: this.form.add2,
        town: this.form.town,
        county: this.form.county,
        postcode: this.form.postcode,
      }
      const res = await api.post("updateHomeAddress", addressDetails)
      if (res.status === 200) {
        this.addressDetails = ""
      }
    },
    validate() {
      this.$refs.form.validate()
    },
    dateOfBirthChanged() {
      this.form.dobText = dayjs(this.form.dob).format("DD/MM/YYYY")
    },
    dobTextChanged() {
      if (this.form.dobText.length >= 1) {
        const tempDay = this.form.dobText.slice(0, 2)
        const tempMonth = this.form.dobText.slice(3, 5)

        if (parseInt(tempDay) > 31) {
          const temp = this.form.dobText.slice(2, 10)
          this.form.dobText = "31" + temp
        }
        if (this.form.dobText.length >= 2 && parseInt(tempDay) === 0) {
          const temp = this.form.dobText.slice(2, 10)
          this.form.dobText = "01" + temp
        }
        if (parseInt(tempMonth) > 12) {
          const temp = this.form.dobText.slice(5, 10)
          this.form.dobText = tempDay + "/12" + temp
        }
        if (this.form.dobText.length >= 5 && parseInt(tempMonth) === 0) {
          const temp = this.form.dobText.slice(5, 10)
          this.form.dobText = tempDay + "/01" + temp
        }
        const dobText = this.form.dobText.split("/").reverse().join("-")
        // const maxDate = dayjs().subtract(18, 'year').format('YYYY-MM-DD')
        const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
        this.form.dob = currentDOB
        if (this.form.dob === "Invalid Date") {
          this.form.dob = ""
        }
        // }
      }
    },
    bindCreditInformation() {
      if (this.formEditMode) {
        Object.entries(this.selectedContact).forEach(
          mergeExistingEntriesIntoForm(this.form)
        )
      }
    },
    async fetchPostCodeAddresses() {
      const response = await this.fetchAddressesByPostcode(this.form.postcode)
      if (response.status === 200) {
        this.inCorrectPostcodeSelected = false
        this.showPostcode = true
        this.addresses = response.addresses
      } else {
        this.inCorrectPostcodeSelected = true
        this.showPostcode = false
      }
    },
    dateOfFromTextChanged() {
      const dateOfFromText = this.form.dateOfFromText
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2})(\d{2})?(\d{4})?/g, "$1/$2/$3")
      this.form.fromDate = dayjs(dateOfFromText).format("YYYY-MM-DD")
      if (this.form.fromDate === "Invalid Date") {
        this.form.fromDate = ""
      }
    },
    dateOfToTextChanged() {
      const dateOfToText = this.form.dateOfToText
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2})(\d{2})?(\d{4})?/g, "$1/$2/$3")
      this.form.toDate = dayjs(dateOfToText).format("YYYY-MM-DD")
      if (this.form.toDate === "Invalid Date") {
        this.form.toDate = ""
      }
    },
  },
}
</script>
